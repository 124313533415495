<!-- eslint-disable-next-line vue/max-lines-per-block -->
<script lang="ts" setup>
import type { TypeToolCompanyRanking } from "~/types/contentful";
import type { TypeDataSourceParsed } from "~/types/TypeDataSourceParsed.js";
import type { TypePdCompany } from "~/types/csv/TypePd";
import type { TypeSpCompany } from "~/types/csv/TypeSp";
import type { TypePpiCompany } from "~/types/csv/TypePpi";
import type { TypeChartDataLayer } from "~/types/TypeChartDataLayer";
import { pushDataLayerEvent } from "~/lib/client-data-layer";
import { updateFiltersChecked, filterArray } from "~/lib/filter";
import {
  isCompanyKey,
  isProteinDiversification,
  isProteinProducerIndex,
  isSustainableProteins,
  isWorkingConditions,
} from "~/lib/company-profile/type-guards.js";
import type { TypeWcCompany } from "~/types/csv/TypeWc";
import type { TypeChartCompany } from "~/types/TypeChartCompany";

const props = defineProps<{
  fields: TypeToolCompanyRanking<
    "WITHOUT_UNRESOLVABLE_LINKS",
    "en-GB"
  >["fields"];
}>();

const { data: dataSourceParsed } = await useLazyFetch<TypeDataSourceParsed>(
  "/api/data-source",
  {
    query: {
      id: props.fields.dataSource?.sys.id,
      project: props.fields.dataSource?.fields.project?.sys.id,
    },
    server: false,
  },
);

const allDataSourcesParsed = computed(() => {
  let dataSource: typeof dataSourceParsed.value | undefined =
    dataSourceParsed.value;

  const dataSources = [];

  if (dataSource) {
    do {
      dataSources.push(dataSource);
    } while ((dataSource = dataSource.previousDataSource));
  }

  return dataSources;
});

const chartYears = computed(() =>
  allDataSourcesParsed.value.map(
    (dataSourceParsed) => dataSourceParsed.content.fields.yearOrPhase,
  ),
);

const yearSelectedIndex = ref(0);

const yearSelected = computed(
  () => chartYears.value[yearSelectedIndex.value] ?? "",
);

const dataSourceByYear = computed(() =>
  allDataSourcesParsed.value.find(
    (dataSourceParsed) =>
      dataSourceParsed.content.fields.yearOrPhase === yearSelected.value,
  ),
);

const regions = computed(() =>
  [
    ...new Set(
      dataSourceByYear.value?.data?.map((row) =>
        "Region" in row ? row.Region : "",
      ),
    ),
  ].sort(),
);

const companyTypes = computed(() =>
  [
    ...new Set(
      dataSourceByYear.value?.data?.map((row) =>
        "Company type" in row ? row["Company type"] : "",
      ),
    ),
  ]
    .filter((type) => type !== "Multiple")
    .sort(),
);

const companyNames = computed(() => [
  ...new Set(
    dataSourceByYear.value?.data
      ?.map((company) => company.Name)
      .filter(Boolean),
  ),
]);

const proteins = computed(() =>
  [
    ...new Set(
      dataSourceByYear.value?.data?.map((row) =>
        "Main Protein Category" in row ? row["Main Protein Category"] : "",
      ),
    ),
  ].sort(),
);

const filterPanelBlocks = computed<string[][]>(() => [
  [...regions.value.filter((val): val is NonNullable<typeof val> => !!val)],
  [
    ...companyTypes.value.filter(
      (val): val is NonNullable<typeof val> => !!val,
    ),
  ],
  [...proteins.value.filter((val): val is NonNullable<typeof val> => !!val)],
  [
    ...companyNames.value.filter(
      (val): val is NonNullable<typeof val> => !!val,
    ),
  ],
]);

const filterTitles = ["Regions", "Company Types", "Proteins", "Companies"];
const filterFields = [
  "Region",
  "Company type",
  "Main Protein Category",
  "Name",
];

const filtersChecked = ref<{ field: string; id: string }[]>([]);

const xValues = computed(() => {
  const grading = Object.keys(dataSourceParsed.value?.scoring?.grading ?? {});
  if (
    isSustainableProteins(dataSourceParsed.value) ||
    isProteinDiversification(dataSourceParsed.value)
  )
    return grading;
  return grading.filter((key) => key === "Risk Score" || key.endsWith("F"));
});
const xSelected = ref("");
const x2Selected = ref("");

watch(dataSourceParsed, () => (xSelected.value = xValues.value[0] ?? ""));

const mainX = computed(() =>
  x2Selected.value ? x2Selected.value : xSelected.value,
);

const onFilterChange = (checked: boolean, id: string, index: number) => {
  const field = filterFields[index] ?? "";

  updateFiltersChecked(filtersChecked.value, field, checked, id);

  if (checked) {
    chartDataLayer.filter = {
      group: field,
      value: id,
    };
    pushDataLayerEvent("evChartFilter", chartDataLayer);
  }
};

const onYear = (value: string) => {
  console.log("onYear", value);

  yearSelectedIndex.value = chartYears.value.indexOf(value);

  chartDataLayer.year = value;
  pushDataLayerEvent("evChartYearSelected", chartDataLayer);
};

const sortDesc = ref(true);

const onSort = (value: string) => {
  sortDesc.value = value === "High to Low";

  chartDataLayer.orderBy = value;
  pushDataLayerEvent("evChartOrderSelected", chartDataLayer);
};

const sortedCompaniesDesc = computed(() => {
  const array = [...(dataSourceByYear.value?.data ?? [])].sort((a, b) => {
    const kpi = mainX.value;

    if (!isCompanyKey(kpi, a) || !isCompanyKey(kpi, b)) {
      return 0;
    }

    const x = Number(a[kpi]);
    const y = Number(b[kpi]);

    return x < y ? 1 : x > y ? -1 : 0;
  });

  return array;
});

const sortedCompanies = computed(() =>
  sortDesc.value
    ? sortedCompaniesDesc.value
    : [...sortedCompaniesDesc.value].reverse(),
);

const filteredCompanies = computed(() => {
  if (filtersChecked.value.length === 0) return sortedCompanies.value;
  if (sortedCompanies.value.length) {
    const regionFilters = filtersChecked.value.filter(
      (filter) => filter.field === "Region",
    );
    const filteredByRegion = filterArray(sortedCompanies.value, regionFilters);
    const typeFilters = filtersChecked.value.filter(
      (filter) => filter.field === "Company type",
    );
    const filteredByType = filterArray(filteredByRegion, typeFilters);
    const nameFilters = filtersChecked.value.filter(
      (filter) => filter.field === "Name",
    );
    const filteredByName = filterArray(filteredByType, nameFilters);
    const proteinFilters = filtersChecked.value.filter(
      (filter) => filter.field === "Main Protein Category",
    );
    const filteredByProtein = filterArray(filteredByName, proteinFilters);
    return filteredByProtein;
  }
  return [];
});

const outlookCodes = computed(() =>
  xValues.value.map((kpi: string) =>
    kpi === "Total Score" ? "Overall Outlook" : kpi + " Outlook",
  ),
);
const selectedOutlook = computed(() =>
  yearSelectedIndex.value !== 0
    ? undefined
    : outlookCodes.value[xValues.value.indexOf(xSelected.value)],
);

const chartCompanies = computed<TypeChartCompany[]>(() => [
  ...new Set(
    filteredCompanies.value
      .filter(
        (company): company is TypePpiCompany | TypeSpCompany =>
          isProteinProducerIndex(dataSourceParsed.value) ||
          isSustainableProteins(dataSourceParsed.value),
      )
      .map((row) => {
        const score = isCompanyKey(mainX.value, row)
          ? Number(row[mainX.value])
          : 0;

        return {
          name: "Name Short" in row ? row["Name Short"] : row.Name,
          values: [
            {
              from: 0,
              to: score,
              field: mainX.value,
              colour:
                dataSourceParsed.value?.scoring?.grading[mainX.value]
                  ?.toReversed()
                  .find((grade) => score >= grade.min && score <= grade.max)
                  ?.colour ?? "",
              outlook:
                isSustainableProteins(dataSourceParsed.value) &&
                isCompanyKey(selectedOutlook.value, row)
                  ? row[selectedOutlook.value]
                  : undefined,
            },
          ],
          csv: row,
        };
      }),
  ),
]);

const chartBgStrips = computed(() =>
  dataSourceParsed.value?.scoring?.grading[xSelected.value]
    ?.filter((grade) => grade.max - grade.min > 1)
    .toReversed(),
);

const chartMaxScore = computed(
  () =>
    dataSourceParsed.value?.scoring?.grading[xSelected.value]?.reduce(
      (max, grade) => (grade.max > max ? grade.max : max),
      0,
    ) ?? 0,
);

const xValuesTitles = computed(() =>
  xValues.value.map((code) => {
    const item = dataSourceParsed.value?.methodology?.find(
      (item) => item.Code === code,
    );
    return item?.Title ? item.Title : code;
  }),
);

const subKpis = computed(() => {
  const kpi = { Code: "", Question: "" };
  if (!xSelected.value || xSelected.value === "Risk Score") return [kpi];
  const kpiBase = xSelected.value.slice(0, -1); // GHGF -> GHG, DEFF -> DEF
  return (
    dataSourceParsed.value?.methodology?.filter(
      (item) => item.Code?.includes(kpiBase) && item.Score === "100",
    ) ?? [kpi]
  );
});

const subKpisCodes = computed(() => subKpis.value.map((kpi) => kpi.Code ?? ""));
const subKpisTitles = computed(() =>
  subKpis.value.map((kpi) => kpi.Question ?? ""),
);

const onXSelect = (x: string, index?: number) => {
  xSelected.value =
    typeof index !== "undefined" ? (xValues.value[index] ?? "") : x;

  x2Selected.value = "";

  chartDataLayer.xValue =
    typeof index !== "undefined" ? xValuesTitles.value[index] : x;
  pushDataLayerEvent("evChartXSelected", chartDataLayer);
};

const onX2Select = (x: string, index?: number) => {
  x2Selected.value =
    typeof index !== "undefined" ? (subKpisCodes.value[index] ?? "") : x;

  chartDataLayer.x2Value =
    typeof index !== "undefined" ? subKpisTitles.value[index] : x;
  pushDataLayerEvent("evChartX2Selected", chartDataLayer);
};

const chartDataLayer = reactive<TypeChartDataLayer>({
  name: props.fields.name,
  project: dataSourceParsed.value?.content.fields.project?.fields.name ?? "",
});
</script>

<template>
  <section class="tool--company-ranking py-4 md:py-6">
    <Container
      v-if="dataSourceParsed?.content.fields.project"
      class="tool-chart"
    >
      <TextSectionTitle
        v-if="
          isWorkingConditions(dataSourceParsed) ||
          isProteinDiversification(dataSourceParsed)
        "
      >
        Company Data
      </TextSectionTitle>
      <TextSectionTitle v-else> Company Ranking </TextSectionTitle>

      <MembersOnly :show-content="true" :show-push="true">
        <template #push-message>
          <p>Login to use the Interactive Tool. Not a member? Sign up today.</p>
        </template>

        <TableCompaniesHeatmap
          v-if="
            filteredCompanies &&
            dataSourceByYear &&
            (isWorkingConditions(dataSourceParsed) ||
              isProteinDiversification(dataSourceParsed))
          "
          :data-source-parsed="dataSourceByYear"
          :chart-years="chartYears"
          :year-selected="yearSelected"
          :companies="
            filteredCompanies.filter(
              (company): company is TypePdCompany | TypeWcCompany =>
                isProteinDiversification(dataSourceParsed) ||
                isWorkingConditions(dataSourceParsed),
            )
          "
          @on-year="onYear"
        />

        <template
          v-else-if="
            isProteinProducerIndex(dataSourceParsed) ||
            isSustainableProteins(dataSourceParsed)
          "
        >
          <div>
            <ChartControls
              :filter-panel-blocks="filterPanelBlocks"
              :filter-titles="filterTitles"
              :x-values="xValues ?? []"
              :x-values-titles="xValuesTitles ?? []"
              :x-values2="
                isSustainableProteins(dataSourceParsed) ? [] : subKpisCodes
              "
              :x-values2-titles="subKpisTitles"
              :x="xSelected"
              x-title="Assessment Criteria"
              :is-company-ranking="true"
              :years="chartYears"
              :year="yearSelected"
              @on-x-select="onXSelect"
              @on-x2-select="onX2Select"
              @on-filter-change="onFilterChange"
              @on-year="onYear"
              @on-sort="onSort"
              @on-title-update="chartDataLayer.title = $event"
            />

            <div class="ms-2 flex ps-4 md:ms-1 lg:ps-0 2xl:ms-0">
              <Chart
                class="mb-5"
                :project="dataSourceParsed.content.fields.project"
                title-left="Score (%)"
                :min-score="0"
                :max-score="chartMaxScore"
                :is-bar-chart="true"
                :step-size="chartMaxScore % 100 === 0 ? 10 : 1"
                :items="chartCompanies"
                :bg-strips="chartBgStrips"
                :year-selected="chartYears[yearSelectedIndex]"
                :selected-outlook="selectedOutlook"
                :selected-kpi="mainX"
              />
            </div>

            <div
              class="tool-chart__keys mx-auto flex items-center px-2 text-xs"
            >
              Companies
            </div>
          </div>

          <TableCompanies
            v-if="
              filteredCompanies[0] &&
              filteredCompanies &&
              'Name Short' in filteredCompanies[0]
            "
            :data-source-parsed="dataSourceParsed"
            :items="
              filteredCompanies.filter(
                (company): company is TypePpiCompany | TypeSpCompany =>
                  isProteinProducerIndex(dataSourceParsed) ||
                  isSustainableProteins(dataSourceParsed),
              )
            "
            :filter-panel-blocks="filterPanelBlocks"
            :filter-titles="filterTitles"
            :outlook-codes="outlookCodes"
            :selected-outlook="selectedOutlook"
            :selected-kpi="mainX"
            :kpis="xValues.filter((kpi) => kpi !== 'Risk Score')"
            @on-type-change="
              chartDataLayer.companiesView = $event;
              pushDataLayerEvent('evChartCompaniesView', chartDataLayer);
            "
          />
        </template>
      </MembersOnly>
    </Container>
  </section>
</template>

<style lang="scss" scoped>
.tool--company-ranking {
  position: relative;

  &:deep(.dropdown) {
    min-width: 250px;

    &#kpi {
      width: 300px;
    }
  }

  &:deep(.chart-years) {
    width: 100%;
  }

  &:deep(.filter-panel) {
    @apply max-lg:mt-3;
  }
}
</style>
