<script lang="ts" setup>
import {
  isProteinDiversification,
  isWorkingConditions,
} from "~/lib/company-profile/type-guards.js";
import type { TypeDataSourceParsed } from "~/types/TypeDataSourceParsed";

const props = defineProps<{
  dataSourceParsed: TypeDataSourceParsed;
  companies: TypeDataSourceParsed["company"][];
  chartYears: string[];
  yearSelected: string;
}>();

const emit = defineEmits(["onYear"]);

const onYearSelect = (checked: boolean, title: string) => {
  emit("onYear", title);
};

const projectSlug = computed(
  () => props.dataSourceParsed.content.fields.project?.fields.slug ?? "",
);

const overviewTitle = "KPI Overview";
const levelsTitle = "Detailed KPI";

const wcCodeReplacements = {
  HS1: "GM",
  HS2: "E1",
  FWC1: "DCT1",
  FWC2: "OGS1",
  WR1: "WR",
  WR2: "EW",
};

const replaceCode = (code: string) =>
  (
    Object.keys(wcCodeReplacements) as (keyof typeof wcCodeReplacements)[]
  ).reduce((acc, key) => acc.replace(key, wcCodeReplacements[key]), code);

const methodology = computed(() => {
  const baseCodes = [
    ...new Set(
      props.dataSourceParsed.methodology
        ?.map((row) => row.Code?.match(/[A-Z]+/)?.[0])
        .filter((code): code is NonNullable<typeof code> => !!code),
    ),
  ];

  return baseCodes.map((baseCode) => {
    const subKpis = isWorkingConditions(props.dataSourceParsed)
      ? (props.dataSourceParsed.methodology?.filter((row) =>
          row.Code.startsWith(baseCode),
        ) ?? [])
      : isProteinDiversification(props.dataSourceParsed)
        ? (props.dataSourceParsed.methodology?.filter(
            (row) =>
              row.Code.startsWith(baseCode) && /^[A-Z]+[0-9]$/.exec(row.Code),
          ) ?? [])
        : [];

    return {
      title: subKpis[0]?.Title ?? "",
      baseCode,
      subKpis: subKpis.map((subKpi) => ({
        title: subKpi.Title,
        ...(isWorkingConditions(props.dataSourceParsed)
          ? {
              code: subKpi.Code,
              title: replaceCode(subKpi.Code),
              question: subKpi.Question,
            }
          : isProteinDiversification(props.dataSourceParsed)
            ? {
                code: subKpi.Code,
                title: subKpi.Code,
                question: subKpi.Question,
              }
            : {}),
      })),
    };
  });
});

const filterOptions = computed(() => [...[levelsTitle], ...[overviewTitle]]);
const filter = ref(levelsTitle);

const calcAverage = (
  code: string,
  company: TypeDataSourceParsed["company"],
) => {
  const subKpis =
    props.dataSourceParsed.methodology
      ?.filter((m) => m.Code?.includes(code))
      .map((kpi) => kpi.Code) ?? [];
  let sum = 0;
  subKpis.forEach((kpi) => (sum += Number(company?.[kpi ?? ""])));

  return sum / subKpis.length;
};
</script>

<template>
  <div class="table--companies pt-4 lg:pt-5">
    <div class="mb-1 items-center justify-between md:mb-3 md:flex">
      <UiDropdown
        :items="filterOptions"
        label="Factor"
        :initial="levelsTitle"
        class="max-md:w-full"
        @select="(value) => (filter = value)"
      />

      <UiRadio
        v-if="chartYears && chartYears.length"
        :items="chartYears"
        class="max-md:ml-1 max-md:mt-2"
        @select="onYearSelect"
      />
    </div>

    <div class="-mx-3 w-[calc(100%+32px)] overflow-scroll px-3">
      <table
        class="mx-[-8px] mt-3 w-[calc(100%+16px)] min-w-[1260px]"
        aria-label="Companies"
        :class="{
          'pd-overview':
            isProteinDiversification(dataSourceParsed) &&
            filter === overviewTitle,
        }"
      >
        <thead>
          <tr v-if="filter === levelsTitle">
            <td />
            <td
              v-for="kpi in methodology"
              :key="kpi.title"
              class="rounded-sm border-x-8 border-white bg-level-standard p-2 text-center text-sm font-bold leading-[1.17]"
              :colspan="kpi.subKpis.length"
            >
              {{ kpi.title }}
            </td>
          </tr>
          <tr>
            <td
              class="text-tag rounded-sm border-x-8 border-white pt-2"
              data-field="Name"
            >
              <div
                class="bg-level-standard p-2 text-sm font-medium leading-[1.17]"
                :class="{
                  'flex h-6 items-center':
                    isProteinDiversification(dataSourceParsed) &&
                    filter === overviewTitle,
                }"
              >
                Company
              </div>
            </td>

            <template v-if="filter === overviewTitle">
              <td
                v-for="kpi in methodology"
                :key="kpi.title"
                class="rounded-sm border-x-8 border-white pt-2"
              >
                <div
                  class="bg-level-standard p-2 text-center text-sm font-bold leading-[1.17]"
                  :class="{
                    'flex h-6 items-center':
                      isProteinDiversification(dataSourceParsed) &&
                      filter === overviewTitle,
                  }"
                >
                  {{ kpi.title }}
                </div>
              </td>
            </template>

            <template v-else>
              <template v-for="kpi in methodology">
                <td
                  v-for="subKpi in kpi.subKpis"
                  :key="subKpi.code"
                  class="text-tag relative grow rounded-sm border-x-8 border-white pt-2"
                  :class="
                    overviewTitle === filter
                      ? '!font-bold !normal-case !tracking-normal'
                      : ''
                  "
                  data-field="Heatmap"
                >
                  <AppTooltip
                    :text="subKpi.question ?? ''"
                    class="absolute left-0 top-0 block size-full"
                  />
                  <span
                    class="block bg-level-standard p-2 text-center text-sm font-medium leading-[1.17]"
                    >{{ subKpi.title }}</span
                  >
                </td>
              </template>
            </template>
          </tr>
        </thead>

        <tbody>
          <tr
            v-for="(company, index) in companies"
            :key="index"
            class="relative py-3 md:py-0"
          >
            <template v-if="company">
              <td
                data-field="Name"
                :style="
                  filter !== levelsTitle
                    ? 'width: ' + 100 / (methodology.length + 1) + '%'
                    : ''
                "
              >
                <div class="flex items-center">
                  <div class="flex flex-col items-start">
                    <div class="text-sm">
                      {{ company.Ticker }}
                    </div>
                    <NuxtLink
                      :to="`/resources/companies-assessed/${company.Ticker}/${dataSourceParsed.content.fields.project?.fields.slug}`"
                      class="wysiwyg border-b border-ui-grey2 font-bold lg:text-sm"
                    >
                      {{
                        company.Name ||
                        company["Company Name"] ||
                        company.Ticker
                      }}
                    </NuxtLink>
                  </div>
                </div>
              </td>

              <template v-if="filter === overviewTitle">
                <TableHeatmapCell
                  v-for="kpi in methodology"
                  :key="kpi.title"
                  :kpi="kpi.baseCode ?? ''"
                  :company-index="index"
                  :data-source-parsed="dataSourceParsed"
                  :project-slug="projectSlug"
                  :value="
                    isWorkingConditions(props.dataSourceParsed)
                      ? calcAverage(kpi.baseCode ?? '', company)
                      : isProteinDiversification(props.dataSourceParsed)
                        ? Number(company[kpi.baseCode])
                        : 0
                  "
                  :style="'width: ' + 100 / (methodology.length + 1) + '%'"
                />
              </template>

              <template v-else>
                <template v-for="kpi in methodology">
                  <TableHeatmapCell
                    v-for="subKpi in kpi.subKpis"
                    :key="subKpi.title"
                    :kpi="subKpi.code ?? ''"
                    :company-index="index"
                    :data-source-parsed="dataSourceParsed"
                    :project-slug="projectSlug"
                    :value="
                      isWorkingConditions(props.dataSourceParsed)
                        ? calcAverage(subKpi.code ?? '', company)
                        : isProteinDiversification(props.dataSourceParsed)
                          ? Number(company[subKpi.code ?? ''])
                          : 0
                    "
                  />
                </template>
              </template>
            </template>
          </tr>
        </tbody>
      </table>
    </div>

    <div class="justify-center pt-3 lg:flex">
      <div
        v-if="isWorkingConditions(dataSourceParsed)"
        class="mb-3 items-center md:flex lg:mr-2"
      >
        <div class="mr-3 font-bold max-md:mb-2">YoY change:</div>
        <div
          class="flex items-center rounded-md border border-ui-grey2 bg-ui-grey4"
        >
          <div class="text-tag flex items-center px-[12px] py-[10px]">
            Increased
            <div class="ml-1 text-lg">
              <NuxtIcon
                class="ms-[3px] w-3 min-w-[16px]"
                name="disclosure-up"
                filled
              />
            </div>
          </div>
          <div class="text-tag flex items-center px-[12px] py-[10px]">
            Decreased
            <div class="ml-1 text-lg">
              <NuxtIcon
                class="ms-[3px] w-3 min-w-[16px]"
                name="disclosure-down"
                filled
              />
            </div>
          </div>
          <div class="text-tag flex items-center px-[12px] py-[10px]">
            Stayed the same
            <div class="ml-1">
              <NuxtIcon
                class="ms-[3px] w-3 min-w-[16px]"
                name="disclosure-stationary"
                filled
              />
            </div>
          </div>
        </div>
      </div>

      <div
        class="mb-3 flex items-center rounded-md border border-ui-grey2 bg-ui-grey4 lg:ml-2"
      >
        <template
          v-for="grade in dataSourceParsed.scoring?.grading.Score"
          :key="grade.text"
        >
          <div class="text-tag px-[12px] py-[10px]">
            {{ grade.text }}
            <span
              :style="{ backgroundColor: grade.colour }"
              class="mb-[-3px] ml-px inline-block size-3 rounded-full"
            />
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
tbody td {
  @apply border-x-8 border-white border-b-ui-grey4 border-b-[1px] px-2 py-[12px];
}

table.pd-overview {
  td {
    width: 15% !important;
  }

  td[data-field="Name"] {
    width: 340px !important;
  }
}
</style>
